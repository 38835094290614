*::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background: #949494;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}
