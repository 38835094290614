.block {
  flex: 1;
  background-color: #f6f7ff;
}

.block.left {
  border-right: 1px solid #e8e8e7;
}

.block .header {
  display: flex;
  height: 70px;
  padding: 10px 15px;
  align-items: center;
}

.block .header span {
  margin-left: 15px;
  font-size: 14px;
}

.addnew-btn {
  padding: 10px;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.addnew-btn:focus,
.addnew-btn:active {
  outline: none;
}

.addnew-btn:hover {
  background-color: whitesmoke;
}

.action-items a {
  cursor: pointer;
}

.group-data {
  flex-basis: 100%;
}

.no-group-data {
  height: 50px;
  padding: 10px 15px;
  background: #fff;
  display: flex;
  align-items: center;
}

.no-group-data span {
  margin-left: 15px;
  font-size: 12px;
}

.no-group-data svg {
  font-size: 16px;
}

.group-data-list {
  width: 100%;
}

.group-item-row {
  display: flex;
  background: #fff;
  border-bottom: 1px solid #e8e8e7;
  /* padding: 10px; */
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.addnew-btn {
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.group-item-row:hover {
  background: #dfdddd;
}

.g-item {
  flex: 1;
  display: flex;
  padding: 15px 0;
  border-right: 1px solid #e8e8e7;
}

.g-checkbox {
  flex: 1;
  text-align: center;
  padding: 15px 0;
}

.g-checkbox + .g-item {
  flex: 11;
}

.g-list {
  flex: 11;
  padding: 0 15px;
}

.g-list .textarea {
  position: relative;
  padding: 10px;
  background-color: #e3f4fc;
  margin-bottom: 15px;
  border-radius: 25px;
}

.col {
  flex: 1;
  display: flex;
}

.g-content {
  flex: 1;
  display: flex;
  /* flex-direction: column; */
}

.g-list .textarea textarea:focus,
.g-list .textarea input:focus {
  border: none;
  outline: none;
}

.g-list .textarea textarea,
.g-list .textarea input {
  resize: none;
  border: none;
  background-color: transparent;
  width: 100%;
}

.g-list .add-icon {
  padding: 10px;
  border-radius: 25px;
  display: inline-block;
  line-height: 10px;
  cursor: pointer;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.g-list .add-icon:hover {
  background-color: #0000003b;
  color: #fff;
}

.form-action-panel {
  /* padding: 15px; */
  margin-bottom: 15px;
  text-align: right;
}

.search-bar {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 0;
  justify-content: flex-end;
  padding-right: 0px;
  position: relative;
  max-width: 400px;
}

.search-bar input[type='text'] {
  /* width: 100%; */
  /* padding: 15px; */
  height: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
}

.search-ic span {
  font-size: 10px;
}

.action-bar {
  flex: 1;
  /* min-width: 100%; */
  display: flex;
  justify-content: flex-end;
}

.search-ic,
.clear-ic {
  cursor: pointer;
  /* margin: 0 10px; */
  padding: 7px;
  transition: background 300ms ease-out;
  /* line-height: 0; */
  /* border-radius: 50px;s */
  /* position: absolute; */
  text-align: center;
  /* width: 60px;
    height: 60px; */
}

.search-bar.active .search-ic {
  /* visibility: hidden; */
  display: none;
}

.search-bar.active .clear-ic {
  /* visibility: visible; */
  display: block;
}

.search-bar.active .search-text {
  width: 100%;
  padding: inherit;
}

.search-bar .search-text {
  background-color: transparent;
  width: 0;
  padding: 0;
  transition: width 300ms ease-out;
  /* margin-right: 30px; */
}

.search-bar .clear-ic {
  display: none;
  cursor: pointer;
}

.search-ic:hover {
  color: #000;
  background-color: #d9d9d99c;
}

.action-bar a[disabled] {
  opacity: 0.7;
  color: #7c7c7c;
  cursor: not-allowed;
}

.action-bar a[disabled]:hover {
  background: none;
  background-color: transparent;
}

.textarea:hover .delete {
  display: inline;
}

.textarea.onlyone:hover .delete {
  display: none;
}

.delete {
  cursor: pointer;
  position: absolute;
  display: none;
  right: 10px;
}

.edit-panel .action-items {
  display: inline;
  flex: none !important;
}

.edit-panel .action-bar {
  justify-content: flex-end !important;
}

.dialog-item {
  width: 100%;
}

@media only screen and (max-width: 960px) {
  .group-item-row {
    flex-direction: column;
  }
}
